.quill.black-theme {
  .ql-toolbar {
    border: 0.5px solid #525252;
  }
  .ql-container {
    border: 0.5px solid #525252;
  }
  .ql-picker-label {
    color: #c1c2c5;
  }
  .ql-stroke {
    stroke: #c1c2c5;
  }
}
